@charset "UTF-8";
@import "ap_preaset";

/*****************
*** common.css ***
*****************/


/* default */
.en_US body,
.en_US h1,
.en_US h2,
.en_US h3,
.en_US h4,
.en_US h5,
.en_US h6 {
	letter-spacing: toRem(-0.2);
}

/* cautionWrap */
.en_US .cautionWrap .list li {
	font-size: 1.4rem;
}


/****************
*** style.css ***
****************/


/* sub */
.en_US .subTit {
    font-size: 3rem;
}
.en_US .subMdTit {
    font-size: 2.2rem;
}
.en_US .subSmTit {
    font-size: 1.8rem;
}

@include rwd('sm'){
	.en_US .subTit {
	    font-size: 2.6rem;
	}
	.en_US .subMdTit {
	    font-size: 1.8rem;
	}
	.en_US .subSmTit {
	    font-size: 1.6rem;
	}
}


/* header */
.en_US .gnb .main > li > .label {
	padding: toRem(20) toRem(30) toRem(40) toRem(30);
	font-size: 1.8rem;
}
.en_US .gnb .sub > li > a {
	min-width: toRem(200);
	width: auto;
	font-size: 1.6rem;
}
.en_US .gnb .subWrap.service .sub {
	margin-top: toRem(30);
	justify-content: space-around;
}
.en_US .gnb .subWrap.service .sub > li {
	margin-right: 0;
}
.en_US .gnb .subWrap.service .title .label {
	font-size: 1.6rem;
}
.en_US .gnb .subWrap.service .list li .label {
	font-size: 1.4rem;
}

@include rwd('xl'){
	.en_US .gnb .main > li > .label {
		padding: toRem(20) toRem(30) toRem(40) toRem(30);
		font-size: 1.6rem;
	}
	.en_US .gnb .subWrap.service .list {
		margin-left: toRem(48);
	}
	.en_US .gnb .subWrap.service .sub > li {
		max-width: toRem(240);
	}
	.en_US .gnb .subWrap.service .title i img {
		width: toRem(33);
		height: toRem(33);
	}
	.en_US .gnb .subWrap.service .title .label {
		line-height: 1.4em;
	}
}


@media only screen and (max-width: 1535px) {	
	.en_US .gnb .logo {
		margin-right: 10%;
	}
	.en_US .gnb .main > li > .label {
		padding: toRem(20) toRem(20) toRem(40) toRem(20);
		font-size: 1.6rem;
	}	
}


/* main */
.en_US .fltConVtc h3 {
	margin-bottom: toRem(102);
	font-size: 2.6rem;
}
.en_US .daterangeWrap .priceWrap .sub .br {
    display: block;
}

@include rwd('lg'){
	.en_US .fltConVtc h3 {
		margin-bottom: toRem(45);
		padding-top: toRem(25);
		font-size: 2.2rem;
	}
	.en_US .fltConVtc .imgVtcBg {
		top: toRem(15);
	}
	.en_US .fltConVtc .imgVtcBg img {
		width: toRem(60);
	}
}


/* footer */
.en_US footer .left {
	width: auto;
}
.en_US footer .left > .box {
	padding-right: toRem(60);
}
.en_US footer .left > .box:last-child {
	padding-right: 0;
}
.en_US footer .right {
	margin-left: toRem(60);
}
.en_US footer .bottom .content ul li span {
	font-size: 1.3rem;
}


@media only screen and (max-width: 1535px) {
	.en_US footer .left > .box {
		padding-right: toRem(30);
	}
	.en_US footer .left li {
		max-width: toRem(160);
		margin-bottom: toRem(15);
		line-height: 1.4em;
	}
}

@include rwd('lg'){
	.en_US footer .right {
		margin-left: 0;
	}	
	.en_US footer .left li {
		max-width: none;
		line-height: 1.6em;
	}
}

@include rwd('md'){
	.en_US footer .bottom .content ul li span {
		margin-bottom: toRem(10);
		line-height: 1.4em;
	}
}


@media only screen and (max-width: 359px) {
	.en_US footer .right .support .timeWrapper {
		display: block;
	}
}


/* 예매 공통 */
.en_US .pBtmWrap .lg_btn .taskButton {
	padding: 0 toRem(5);
}


/*****************
*** style2.scss ***
*****************/


/* 조회/체크인 */
.en_US .dacWrap .realTimeChk .secSlt .asOf {
	width: toRem(250);
}
.en_US .dacWrap .realTimeChk .secSlt .slctBox {
	width: calc(100% - #{toRem(300)});
}




